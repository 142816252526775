import React from 'react';

import './roundednumber.scss';

function RoundedNumber({ number, onClick }) {
    return (
        <div className="rounded-number" onClick={onClick}>
            {number}
        </div>
    );
}

export default RoundedNumber;
