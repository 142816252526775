import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import { Decimal } from 'decimal.js';

import Point from '../../assets/point.png';

export const MAP_CENTER_BOSNIA = { lat: 43.9755833, lng: 17.8223669 };

export function getCitiesMarkers(pharmacies) {
    // [Sarajevo: 2]
    const citiesNumbers = groupBy(pharmacies, 'city');

    const pharmaciesCities = uniqBy(pharmacies, e => e.city);

    const citiesMarkers = [];

    Object.entries(citiesNumbers)
        .forEach(([key, value]) => {
            for (let pharmacy of pharmaciesCities) {
                // console.log(pharmacy);
                if (pharmacy.city === key) {
                    citiesMarkers.push({
                        name: key,
                        number: value.length > 1 ? value.reduce((acc, v) => (isNaN(acc) ? acc.screens : acc) + v.screens) : value[0].screens,
                        showInfoWindow: false,
                        position: {
                            lat: (new Decimal(pharmacy.lat)).add(0.000002).toNumber(),
                            lng: (new Decimal(pharmacy.lan)).minus(0.000001).toNumber(), // this is required for proper centering
                        },
                        icon: Point,
                    });
                    break;
                }
            }
        });

    return citiesMarkers;
}

export function getCityPharmacyMarkers(pharmacies, city) {
    const pharmaciesCity = pharmacies.filter(pharmacy => pharmacy.city === city);

    return pharmaciesCity.map(pharmacy => {
        return {
            ...pharmacy,
            description: ` | ${pharmacy.address}`,
            icon: Point,
            showInfoWindow: true,
            position: {
                lat: +pharmacy.lat,
                lng: +pharmacy.lan,
            },
        };
    });
}

export function updateState(setState, newState) {
    return setState(prevState => {
        return { ...prevState, ...newState };
    });
}
