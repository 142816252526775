import React, { useEffect, useRef } from 'react';

import './map.scss';
import CustomMarker from './CustomMarker/CustomMarker';

import { GoogleMap, LoadScript } from '@react-google-maps/api';

const style = [{
    'featureType': 'all',
    'elementType': 'labels.text.stroke',
    'stylers': [{ 'color': '#000000' }, { 'lightness': 16 }, { 'visibility': 'off' }],
}, {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [{ 'color': '#000000' }, { 'lightness': 20 }],
}, {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [{ 'color': '#000000' }, { 'lightness': 17 }, { 'weight': 1.2 }],
}, {
    featureType: 'all',
    elementType: 'labels',
    stylers: [
        { visibility: 'off' },
    ],
}, {
    'featureType': 'administrative.country',
    'stylers': [{ 'visibility': 'simplified' }],
}, {
    'featureType': 'administrative.country',
    'elementType': 'geometry',
    'stylers': [{ 'visibility': 'simplified' }],
}, {
    'featureType': 'administrative.country',
    'elementType': 'labels.text',
    'stylers': [{ 'visibility': 'simplified' }],
}, {
    'featureType': 'administrative.land_parcel',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'administrative.locality',
    'stylers': [{ 'saturation': '-100' }, { 'lightness': '30' }, { 'visibility': 'simplified' }],
}, {
    'featureType': 'administrative.neighborhood',
    'stylers': [{ 'visibility': 'off' }],
}, { 'featureType': 'administrative.province', 'stylers': [{ 'visibility': 'off' }] }, {
    'featureType': 'landscape',
    'stylers': [{ 'lightness': '74' }, { 'gamma': '0.00' }, { 'visibility': 'simplified' }],
}, {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#34334f' }, { 'lightness': -35 }],
}, {
    'featureType': 'landscape',
    'elementType': 'labels.icon',
    'stylers': [{ 'visibility': 'off' }],
}, { 'featureType': 'landscape.man_made', 'stylers': [{ 'lightness': '3' }] }, {
    'featureType': 'poi',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#000000' }, { 'lightness': 21 }],
}, {
    'featureType': 'poi',
    'elementType': 'labels.icon',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [{ 'visibility': 'simplified' }],
}, {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#2d2c45' }, { 'lightness': '1' }],
}, {
    'featureType': 'road.arterial',
    'elementType': 'labels.text',
    'stylers': [{ 'visibility': 'on' }],
}, {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.fill',
    'stylers': [{ 'color': '#7d7c9b' }],
}, {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.stroke',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [{ 'color': '#2d2c45' }, { 'lightness': '0' }],
}, {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [{ 'color': '#000000' }, { 'lightness': 29 }, { 'weight': 0.2 }],
}, {
    'featureType': 'road.highway',
    'elementType': 'labels.text.fill',
    'stylers': [{ 'color': '#7d7c9b' }, { 'lightness': '43' }],
}, {
    'featureType': 'road.highway',
    'elementType': 'labels.text.stroke',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#2d2c45' }, { 'lightness': '-1' }, { 'gamma': '1' }],
}, {
    'featureType': 'road.local',
    'elementType': 'labels.text',
    'stylers': [{ 'hue': '#ff0000' }, { 'visibility': 'on' }],
}, {
    'featureType': 'road.local',
    'elementType': 'labels.text.fill',
    'stylers': [{ 'color': '#7d7c9b' }, { 'lightness': '-31' }],
}, {
    'featureType': 'road.local',
    'elementType': 'labels.text.stroke',
    'stylers': [{ 'visibility': 'off' }],
}, {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#2d2c45' }, { 'lightness': '-36' }],
}, {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [{ 'color': '#2d2c45' }, { 'lightness': '0' }, { 'gamma': '1' }],
}, { 'featureType': 'water', 'elementType': 'labels.text.stroke', 'stylers': [{ 'visibility': 'off' }] }];

function Map({ markers, isCityLevel, onMarkerClick, mapCenter, mapZoom, currentMarker, closeCurrentMarker, updateMapCenter }) {
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.panTo(mapCenter);
        }
    }, [mapCenter]);

    return (
        <LoadScript
            onError={(error) => console.log('error', error)}
            id="script-loader"
            googleMapsApiKey="AIzaSyDI7CP1x4PvtoYv3w0iYxl1xBrV5RZMghI">
            <GoogleMap
                onLoad={(map) => {
                    mapRef.current = map;
                }}
                mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                }}
                options={{
                    styles: style,
                    streetViewControl: false,
                    mapTypeControl: false,
                    minZoom: 8,
                    scaleControl: false,
                    disableDoubleClickZoom: !Boolean(currentMarker),
                    draggable: !Boolean(currentMarker),
                    scrollwheel: false,
                    restriction: {
                        latLngBounds: {
                            north: 45.258452,
                            west: 15.594411,
                            south: 42.469619,
                            east: 19.719745,
                        },
                        strictBounds: true,
                    },
                    zoomControl: false,
                    fullscreenControl: false,
                }}
                zoom={mapZoom}
                center={mapCenter}
                onDragEnd={() => {
                    updateMapCenter(mapRef.current.center.lat(), mapRef.current.center.lng());
                }}
                id='google-map'>
                {markers && markers.map((marker, i) => {
                    return (
                        <CustomMarker marker={marker}
                                      key={i}
                                      closeCurrentMarker={closeCurrentMarker}
                                      currentMarker={currentMarker}
                                      onMarkerClick={onMarkerClick}/>
                    );
                })}
            </GoogleMap>
        </LoadScript>
    );
}

export default Map;
