import React, { useEffect } from 'react';

import { Marker, OverlayView } from '@react-google-maps/api';
import InfoWindowContent from './InfoWindowContent';
import { useKeyPress } from '../../utils/useKeyPress';

function CustomMarker({ marker, onMarkerClick, currentMarker, closeCurrentMarker }) {
    const escPress = useKeyPress('Escape');

    useEffect(() => {
        if (escPress === true) {
            closeCurrentMarker();
        }
    }, [escPress]);

    function onClick() {
        onMarkerClick(marker);
    }

    return (
        <Marker {...marker}
                onClick={onClick}>
            {currentMarker === marker.id && Boolean(marker.showInfoWindow) && (
                <OverlayView position={marker.position}
                             getPixelPositionOffset={(width, height) => {
                                 // todo fix this
                                 // if (window.screen.width < 600) {
                                 //     width = 350;
                                 //     height = 326;
                                 // } else {
                                 //     width = 600;
                                 //     height = 462;
                                 // }

                                 return {
                                     x: -(width / 2),
                                     y: -(height / 2),
                                 };
                             }}
                             onLoad={(e) => {
                                 // console.log(e);
                             }}
                             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    <InfoWindowContent closeCurrentMarker={closeCurrentMarker} marker={marker}/>
                </OverlayView>
            )}
        </Marker>
    );
}

export default CustomMarker;
