import React from 'react';

import './listitem.scss';
import RoundedNumber from '../../../RoundedNumber/RoundedNumber';


function ListItem({ item, onClick }) {
    const { name, number } = item;

    return (
        <div className="list-item" onClick={() => onClick(item)}>
            <div className="list-item-name-description-container">
                <span className={'name'}>{name}</span>
            </div>
            <RoundedNumber number={item.screens || number}/>
        </div>
    );
}

export default ListItem;
