import React from 'react';
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './infowindowcontent.scss';

function InfoWindowContent({ marker, closeCurrentMarker }) {
    const { infoWindowData } = marker;

    return (
        <div className="info-window">
            <div className="close-btn" onClick={closeCurrentMarker}>×</div>
            <div className="info-metadata">
                <span className="name">{marker.name}</span><span className="city">, {marker.city}</span>
                <br/>
                <span className="description">{marker.address}</span>
            </div>
            <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                renderLeftNav={(onClick, disabled) => {
                    return (
                        <button
                            className='gallery-nav custom'
                            disabled={disabled}
                            onClick={onClick}>
                            <FontAwesomeIcon icon="arrow-left" size={'2x'} color={'#7e72f2'}/>
                        </button>
                    );
                }}
                renderRightNav={(onClick, disabled) => {
                    return (
                        <button
                            className='gallery-nav custom right'
                            disabled={disabled}
                            onClick={onClick}>
                            <FontAwesomeIcon icon="arrow-right" size={'2x'} color={'#7e72f2'}/>
                        </button>
                    );
                }}
                items={infoWindowData.imgsUrls.map(url => {
                    return {
                        original: url,
                        thumbnail: url,
                        originalClass: 'original',
                    };
                })}/>
        </div>
    );
}

export default InfoWindowContent;
