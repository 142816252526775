import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './assets/App.scss';
import Map from './components/Map/Map';
import Spinner from './components/Spinner/Spinner';
import Sidebar from './components/Sidebar/Sidebar';
import { getCitiesMarkers, getCityPharmacyMarkers, MAP_CENTER_BOSNIA, updateState } from './components/utils/utils';
import translations from './lang/translations';

library.add(faArrowRight, faArrowLeft);

function App() {
    const [data, setData] = useState(null);
    const { t, i18n } = useTranslation();


    const [appState, setAppState] = useState({
        sidebarTitle: t(translations.PHARMACY_LOCATIONS),
        sidebarSubtitle: t(translations.TOTAL_POSITIONS),
        sidebarItems: [],
        isCityLevel: false,
        currentCity: null,
        mapCenter: MAP_CENTER_BOSNIA,
        mapMarkers: [],
        mapZoom: 8,
        currentMarker: null,
        totalNo: 0,
    });

    const changeLang = lng => i18n.changeLanguage(lng);

    useEffect(() => {
        async function fetchData() {
            const data = await fetch('location_map.json').then(r => r.json());
            setData(data);

            const citiesMarkers = getCitiesMarkers(data.pharmacies);

            updateState(setAppState, {
                sidebarItems: citiesMarkers,
                mapMarkers: citiesMarkers,
                totalNo: data.pharmacies.reduce((acc, value) => (isNaN(acc) ? acc.screens : acc) + value.screens),
            });
        }

        fetchData();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        changeLang(params.get('lang'));
    }, [window.location.search]);

    function onMarkerClick(marker) {

        if (appState.isCityLevel) {
            updateState(setAppState, {
                mapCenter: marker.position,
                currentMarker: marker.id,
            });
            return;
        }

        const cityPharmacies = getCityPharmacyMarkers(data.pharmacies, marker.name);

        let zoom = 12;
        if (marker.name === 'Kakanj' || marker.name === 'Zenica' || marker.name === 'Ilidža') {
            zoom = 14.5;
        }

        updateState(setAppState, {
            mapCenter: marker.position,
            mapZoom: zoom,
            mapMarkers: cityPharmacies,
            sidebarTitle: t(translations.PHARMACY_LOCATIONS),
            isCityLevel: true,
            currentCity: marker,
            sidebarSubtitle: marker.name,
            sidebarItems: cityPharmacies,
            cityNo: marker.number,
        });
    }

    function updateMapCenter(lat, lng) {
        updateState(setAppState, {
            mapCenter: { lat, lng },
        });
    }

    function onSidebarBackClick() {
        let citiesMarkers = getCitiesMarkers(data.pharmacies);

        updateState(setAppState, {
            sidebarItems: citiesMarkers,
            sidebarSubtitle: t(translations.TOTAL_POSITIONS),
            isCityLevel: false,
            currentCity: null,
            mapCenter: MAP_CENTER_BOSNIA,
            mapMarkers: citiesMarkers,
            mapZoom: 8,
        });
    }

    function closeCurrentMarker() {
        updateState(setAppState, {
            currentMarker: null,
        });
        if (appState.isCityLevel) {
            const { lat, lng } = appState.currentCity.position;
            updateMapCenter(lat, lng);
        }
    }

    function onListItemClick(listItem) {
        onMarkerClick(listItem);
    }

    return (
        <div className="app">
            <div className="sidebar-container">
                {data === null ? (
                    <Spinner/>
                ) : (
                    <Sidebar title={appState.sidebarTitle}
                             subtitle={appState.sidebarSubtitle}
                             onItemClick={onListItemClick}
                             onBackClick={onSidebarBackClick}
                             isBack={appState.isCityLevel}
                             listItems={appState.sidebarItems}
                             totalPharmaciesNumber={appState.isCityLevel ? appState.cityNo : appState.totalNo}/>
                )}
            </div>
            <div className="map-container">
                {data === null ? (
                    <Spinner/>
                ) : (
                    <Map onMarkerClick={onMarkerClick}
                         closeCurrentMarker={closeCurrentMarker}
                         mapCenter={appState.mapCenter}
                         isCityLevel={appState.isCityLevel}
                         mapZoom={appState.mapZoom}
                         currentMarker={appState.currentMarker}
                         markers={appState.mapMarkers}
                         updateMapCenter={updateMapCenter}/>
                )}
            </div>
        </div>
    );
}

export default App;
