import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './sidebar.scss';
import ListItem from './components/ListItem/ListItem';
import RoundedNumber from '../RoundedNumber/RoundedNumber';

function Sidebar({ listItems, title, subtitle, isBack, onBackClick, onItemClick, totalPharmaciesNumber }) {

    return (
        <div className="sidebar">
            <div className="title-container">
                {isBack ? (
                    <div onClick={onBackClick} className='back-wrapper'>
                        <RoundedNumber
                            number={<FontAwesomeIcon icon="arrow-left" size={'xs'} color={'#7e72f2'}/>}/>
                        <span>{title}</span>
                    </div>
                ) : (
                    <span>{title}</span>
                )}
            </div>
            <div className="subtitle-container">
                <span className="subtitle">{subtitle}</span>
                <RoundedNumber number={totalPharmaciesNumber}/>
            </div>

            <div className="list-container">
                {listItems.map((item, i) => {
                    return (
                        <ListItem key={i}
                                  onClick={onItemClick}
                                  item={item}/>
                    );
                })}
            </div>
        </div>
    );
}

export default Sidebar;
