import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


// the translations
// (tip move them in a JSON file and import them)

import en from './lang/en.json';
import bs from './lang/bs.json';

const resources = {
    en: {
        translation: en,
    },
    bs: {
        translation: bs,
    },
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        detection: {
            order: ['querystring'],
            lookupQuerystring: 'lang',
        },
        fallbackLng: 'bs',
    });

export default i18n;
