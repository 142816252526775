import React from 'react';
import Spin from 'react-spinkit';

function Spinner() {
    return (
        <div className="spinner-container">
            <Spin name="line-scale"/>
        </div>
    );
}

export default Spinner;
